import { useEffect, ReactElement, useState } from "react";
import { GetServerSideProps } from "next";
import Head from "next/head";
import { useOrganisation } from "../../../domains/patient-initiated/context/OrganisationContext";
import { NextPageWithLayout } from "../../_app.page";
import { isOrganisationEnabled } from "./index.page.utils";
import {
    fetchOrganisationSettings,
    LandingPageStates,
    OrganisationSettings,
} from "../../../domains/patient-initiated/api/organisation";
import { PageLayout } from "../../../domains/patient-initiated/components/PageLayout";
import { Feedback, Ds } from "@accurx/design";
import {
    fetchSections,
    Section,
} from "../../../domains/patient-initiated/api/sections";
import { useContent } from "../../../domains/patient-initiated/context/ContentContext";
import {
    ReceptionFlowProvidedPatientDetails,
    fetchPatientDetailsInReceptionFlow,
} from "../../../domains/patient-initiated/api/reception";
import { PatientReceptionSection } from "../../../domains/patient-initiated/components/PatientReceptionSection";
import { DefaultLandingPageSection } from "../../../domains/patient-initiated/components/DefaultLandingPageSection";
import {
    DEFAULT_RECIPIENT_PATIENT_DETAILS,
    RECEPTION_RECIPIENT_PATIENT_DETAILS,
} from "../../../domains/patient-initiated/constant/patientDetails";
import { NHSLogin } from "../../../domains/patient-initiated/components/NHSLogin";
import { StyledHr, StyledNhsAppWrapper } from "./index.page.styles";
import { EventUserType } from "@accurx/shared";
import { useAnalytics } from "../../../domains/patient-initiated/context/AnalyticsContext";
import {
    TriageBaseProps,
    trackPatientInitiatedOutOfHoursPageShown,
    trackTriageFormStartPageView,
} from "../../../domains/patient-initiated/analytics/PatientInitiatedEvents";
import { useNhsApp } from "../../../domains/patient-initiated/hooks";
import { useRouter } from "next/router";
import {
    getPatientInitiatedRoute,
    PATHS,
} from "../../../domains/patient-initiated/constant/paths";
import { useAnswer } from "../../../domains/patient-initiated/context/AnswerContext";
import {
    DEFAULT_RECEPTION_RECIPIENT,
    DEFAULT_RECIPIENT,
} from "../../../domains/patient-initiated/settings";
import { MaintenanceSection } from "../../maintenance";
import { usePhotoUpload } from "../../../domains/patient-initiated/context/PhotoUploadContext";
import { getNhsLoginType } from "../../../domains/patient-initiated/util/nhsLogin.utils";
import { PageFooter } from "../../../domains/patient-initiated/components/PageFooter/PageFooter";

export type PatientInitiatedPageProps = {
    organisationSettings: OrganisationSettings;
    sections: Section[];
    receptionPatientDetails: ReceptionFlowProvidedPatientDetails | null;
    token?: string;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
    const organisationCode = context.params?.organisationCode;

    if (typeof organisationCode !== "string" || !isOrganisationEnabled()) {
        return { notFound: true };
    }

    const token =
        typeof context.query.token === "string" ? context.query.token : "";
    const requestHeaders = context.req.headers;

    const responses = await Promise.all([
        fetchOrganisationSettings({
            organisationCode,
            token,
            requestHeaders,
        }),
        fetchSections({
            organisationCode,
            token,
            requestHeaders,
        }),
        token
            ? fetchPatientDetailsInReceptionFlow(token, requestHeaders)
            : Promise.resolve(null),
    ]);

    const [
        organisationSettingsResponse,
        sectionsResponse,
        receptionFlowAPIResponse,
    ] = responses;

    if (organisationSettingsResponse === null || sectionsResponse === null) {
        return { notFound: true };
    }

    return {
        props: {
            organisationSettings:
                organisationSettingsResponse.organisationSettings,
            sections: sectionsResponse.sections,
            receptionPatientDetails: receptionFlowAPIResponse
                ? receptionFlowAPIResponse.patientDetails
                : null,
            token,
        },
    };
};

const PatientInitiatedPage: NextPageWithLayout<PatientInitiatedPageProps> = ({
    organisationSettings,
    sections,
    receptionPatientDetails,
    token,
}: PatientInitiatedPageProps): JSX.Element => {
    const {
        setOrganisationSettings,
        setReceptionPatientDetails,
        providedPatientDetails,
        resetOrgState,
    } = useOrganisation();

    const { setSelectedRecipient, resetAnswersState } = useAnswer();
    const { resetPhotoUploadState } = usePhotoUpload();
    const { setContentForLanguage, meta } = useContent();
    const { setBaseAnalytics } = useAnalytics();
    const isUsingNhsApp = useNhsApp();
    const router = useRouter();
    const [isNhsApp, setIsNhsApp] = useState<boolean>(false);

    const baseAnalyticsProps: TriageBaseProps = {
        anonymousRequestId: organisationSettings.requestTrackingId,
        isReceptionFlow: receptionPatientDetails !== null,
        loginType: getNhsLoginType(providedPatientDetails !== null, isNhsApp),
        eventUserType: receptionPatientDetails
            ? EventUserType.HealthcareProfessional
            : EventUserType.Patient,
        userOrganisationId: organisationSettings.organisationId,
        clinicianAppId: receptionPatientDetails?.clinicianAppId ?? "",
    };

    const trackLandingPageForm = () => {
        if (
            !organisationSettings.requestTrackingId ||
            !organisationSettings.landingPageState
        )
            return;

        if (
            [
                LandingPageStates.OUT_OF_HOURS_PATIENT_TRIAGE_ENABLED,
                LandingPageStates.OUT_OF_HOURS_WITH_ADMIN_ONLY_ENABLED,
                LandingPageStates.OUT_OF_HOURS_PATIENT_TRIAGE_SUSPENDED,
            ].includes(organisationSettings.landingPageState)
        ) {
            return trackPatientInitiatedOutOfHoursPageShown({
                landingPageState: organisationSettings.landingPageState,
                ...baseAnalyticsProps,
            });
        }

        return trackTriageFormStartPageView({
            landingPageState: organisationSettings.landingPageState,
            ...baseAnalyticsProps,
        });
    };

    useEffect(() => {
        resetOrgState();
        resetPhotoUploadState();
        resetAnswersState();
        setBaseAnalytics(baseAnalyticsProps);
        setOrganisationSettings(organisationSettings);
        setReceptionPatientDetails(receptionPatientDetails);
        setContentForLanguage({
            language: "en",
            content: {
                landingPageSettingsContent:
                    organisationSettings.landingPageSettings,
                sections,
                recipientDetailsQuestions: receptionPatientDetails
                    ? RECEPTION_RECIPIENT_PATIENT_DETAILS
                    : DEFAULT_RECIPIENT_PATIENT_DETAILS,
            },
        });
        setSelectedRecipient(
            receptionPatientDetails
                ? DEFAULT_RECEPTION_RECIPIENT
                : DEFAULT_RECIPIENT,
        );
        trackLandingPageForm();
        // including setContentForLanguage was causing unnecessary re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        organisationSettings,
        setOrganisationSettings,
        sections,
        setReceptionPatientDetails,
        setBaseAnalytics,
    ]);

    useEffect(() => {
        setIsNhsApp(-1 !== window?.navigator?.userAgent?.indexOf("nhsapp"));
        if (!isUsingNhsApp) return;
        router.push(
            getPatientInitiatedRoute(
                router.query.organisationCode,
                PATHS.confirmNotEmergency,
                router.query,
            ),
        );
    }, [isUsingNhsApp, router.query, router]);

    const isNhsLoginFeatureEnabled =
        organisationSettings.organisationFeatures?.patientInitiatedNhsLogin;

    const isDownForMaintenance = organisationSettings.isDownForMaintenance;

    const isNhsLoginDisplayed =
        (isNhsLoginFeatureEnabled && !token) ||
        typeof router.query?.requestType === "string";

    const Wrapper = isNhsApp ? StyledNhsAppWrapper : "div";

    return (
        <>
            <Head>
                <meta name="robots" content="noindex" />
                <title>{meta.contactAboutRequest}</title>
            </Head>
            {token && !receptionPatientDetails && (
                <Feedback
                    colour="error"
                    title={meta.pages.reception.failedToFindPatient}
                    titleAs="h2"
                />
            )}
            <Wrapper>
                <Ds.Grid gap="2">
                    {isDownForMaintenance ? (
                        <MaintenanceSection />
                    ) : (
                        <>
                            {receptionPatientDetails ? (
                                <PatientReceptionSection />
                            ) : (
                                <DefaultLandingPageSection />
                            )}
                            {isNhsLoginDisplayed && (
                                <>
                                    <StyledHr />
                                    <NHSLogin />
                                </>
                            )}
                            <PageFooter />
                        </>
                    )}
                </Ds.Grid>
            </Wrapper>
        </>
    );
};

PatientInitiatedPage.getLayout = function getLayout(page: ReactElement) {
    return (
        <PageLayout
            hasPageStyling={
                page.props.organisationSettings.isDownForMaintenance
            }
        >
            {page}
        </PageLayout>
    );
};

export default PatientInitiatedPage;
