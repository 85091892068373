import { Link, Ds } from "@accurx/design";
import { URL } from "../../../patient-initiated/constant/url";
import { useContent } from "../../context/ContentContext";
import styled from "styled-components";

const StyledHr = styled.hr`
    margin: 0;
`;

export const PageFooter = (): JSX.Element => {
    const { meta } = useContent();

    return (
        <>
            <StyledHr />
            <Ds.Text mt="2">
                {meta.pages.landing.footer.intro}{" "}
                <Link href={URL.PATIENT_SECURITY} openInNewTab theme="light">
                    <Link.Text text={meta.pages.landing.footer.link} />
                    <Link.Icon />
                </Link>{" "}
                {meta.pages.landing.footer.outro}
            </Ds.Text>
        </>
    );
};
