import React, { ComponentProps, ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import { Ds, Link, Text } from "@accurx/design";

export type MarkdownRendererProps = {
    markdown: string;
    overrides?: ComponentProps<typeof ReactMarkdown>["renderers"];
};

export const HeadingRenderer = ({
    level,
    children,
}: {
    level: number;
    children: ReactNode;
}): JSX.Element => {
    switch (level) {
        case 1:
            return (
                <Text as="h1" variant="title" skinny>
                    {children}
                </Text>
            );
        default:
            return <Text as={`h${level}`}>{children}</Text>;
    }
};

const MarkdownRenderer = ({
    markdown,
    overrides = {},
}: MarkdownRendererProps): JSX.Element => (
    <ReactMarkdown
        // eslint-disable-next-line react/no-children-prop
        children={markdown}
        renderers={{
            paragraph: ({ children }) => <Ds.Text>{children}</Ds.Text>,
            bold: ({ children }) => <strong>{children}</strong>,
            link: ({ href, children }) => (
                <Link href={href ?? ""} openInNewTab>
                    {children}
                    <Link.Icon />
                </Link>
            ),
            listItem: ({ children }) => (
                <Text skinny as="li" variant="body">
                    {children}
                </Text>
            ),
            heading: ({ level, children }) =>
                HeadingRenderer({ level, children }),
            ...overrides,
        }}
    />
);

export { MarkdownRenderer };
